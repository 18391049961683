<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">智能接入中间件平台</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <img src="../../../../assets/mobile/img/product/middleware.jpg" alt="" class="pic">
                <div class="title">智能接入中间件平台</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中间件平台是物联网软件产品，是介于AGV硬件系统与应用系统中间，具有承上启下的作用，通过应用系统的操作将指令信息集成、逻辑处理，传给AGV硬件系统进行设备操作，同时将设备系统状态，位置等信息进行逻辑处理后并反馈给应用软件进行显示、处理，实现沟通交互。主要的目的是解决设备系统接入、调度、系统融合互联互通的问题。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中间件平台具备更强的功能，不仅包含以上中间件平台的所有功能，而且还兼有界面展示、配置中心等功能，提供统一的接口文档和数据处理服务，以及统一的上层应用接口，可以方便客户更快接入行业应用系统，可广泛应用于智能工厂等领域。
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>
    
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>
  
<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 800px;
    height: 200px;
    font-size: 72px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .pic {
    margin-left: 70px;
    margin-bottom: 150px;
    width: 1500px;
}

.container .containerBox .title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 80px;
}

.container .containerBox .text {
    margin-bottom: 50px;
    font-size: 70px;
    color: #999;
}
</style>